<template>
  <div class="category-form">
    <v-form ref="form" class="category-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <label class="text-field-label">Category</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Name"
        v-model="form.label"
        :error-messages="form.$getError('label')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Building(s)</label>
      <SelectMultipleBuildings
        flat
        solo
        required
        class="mt-2"
        :label="'Select buildings'"
        :placeholder="'Select buildings'"
        v-model="form.building_id"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('building_id')"
        :loading="form.$busy"
        :disabled="form.$busy"
        :deletableChips="true"
        :isHasSelectAll="true"
      />

      <v-checkbox
        v-model="form.is_active"
        hide-details="auto"
        label="Set as active"
        class="mt-0"
      ></v-checkbox>

      <div class="mt-4">
        <v-btn
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Maintenance category Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'

export default {
  components: { SelectMultipleBuildings },
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    category: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        label: '',
        building_id: [],
        is_active: false,
      }),
    }
  },

  computed: {
    ...mapState({
      createdCategory: (state) => state.category.categoryDetails,
    }),

    header() {
      return this.isUpdate ? 'Category Information' : 'Create Category'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateCategory : this.createCategory
    },

    isUpdate() {
      return !!this.category
    },
  },

  watch: {
    category(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createCategory: 'category/createCategory',
      updateCategory: 'category/updateCategory',
    }),

    initForm() {
      if (this.category) {
        this.form.building_id = [...this.category.buildings].map(
          (item) => item.id
        )
        this.form.label = this.category.label
        this.form.is_active = this.category.is_active
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Category details successfully updated!')
          } else {
            this.showSnackbar('Category successfully created!')
            this.$router.push({
              name: 'maintenance.categories',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      form.type = CATEGORY_TYPE.general

      if (this.isUpdate) {
        form.id = this.category.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.category-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
